import React, { useState, useEffect } from 'react';
import { rejectResponseProviders, rejectResponseSuppliers } from '../../../services/OrdersService';
import { useTranslation } from 'react-i18next';
/*
props:
- symbol
- data
*/
function OrdersRow(props) {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    const id = localStorage.getItem('id');

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    
    const [negados, setNegados] = useState(0);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        /*if (props.data.services && props.data.services.length > 0) {
            const negados = props.data.services.filter(order => order.winner === false && order.active === false || order.denied === true);
            setNegados(negados.length);
        } else if (props.data.suppliers && props.data.suppliers.length > 0) {
            const negados = props.data.suppliers.filter(order => order.winner === false && order.active === false || order.negado === true);
            setNegados(negados.length);
        } else {
            setNegados(0);
        }*/
        excludeService();
    }, [props.data]);

    function handleRowClick() {
        if (!props.data) {
            console.error("props.data is undefined or null");
            return;
        }

        const myLocation = {
            city: props.data.city,
            street: props.data.street,
            number: props.data.number
        };

        if (props.onMyLocation) {
            props.onMyLocation(myLocation);
        }

        if (props.myType !== '1') return props.onViewOrder([]);

        if (props.data.active === false) {
            if (props.data.services && props.data.services.length > 0) {
                const vencedores = props.data.services.filter(order => order.winner === true);
                const myDestination = {
                    city: vencedores[0].city,
                    street: vencedores[0].street,
                    number: vencedores[0].number
                };

                if (props.onMyDestination) {
                    props.onMyDestination(myDestination);
                }
            } else if (props.data.suppliers && props.data.suppliers.length > 0) {
                const vencedores = props.data.suppliers.filter(order => order.winner === true);
                const myDestination = {
                    city: vencedores[0].city,
                    street: vencedores[0].street,
                    number: vencedores[0].number
                };

                if (props.onMyDestination) {
                    props.onMyDestination(myDestination);
                }
            }
            props.onActive(props.data.active);
            props.onViewOrder([]);
            return;
        }

        if (props.data.suppliers && props.data.suppliers.length > 0) {
            props.onViewOrder(props.data.suppliers);
        } else if (props.data.services && props.data.services.length > 0) {
            props.onViewOrder(props.data.services);
        } else if (props.data.favoriteId !== 0) {
            console.log("PEGA DESTINO DO ESCOLHIDO");
        } else {
            props.onActive(false);
            props.onViewOrder([]);
            return;
        }
        props.onActive(props.data.active);
    }

    function formatarDataHora() {
        var data_hora = new Date(props.data.createdAt);
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1;
        var ano = data_hora.getFullYear() % 100;
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();

        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }

        var data_formatada;

        if (isLargeScreen) {
            data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
        } else {
            data_formatada = dia + '/' + mes + '/' + ano;
        }

        return data_formatada;
    }

    const excludeService = async () => {

        const isDateExpired = (availabilityString) => {
          if (!availabilityString) {
            return false; // Se não houver disponibilidade, não considera expirado
          }
      
          // Tenta fazer o parsing correto da data
          try {
            const availabilityDates = availabilityString.split(',').map(dateStr => {
              const date = new Date(dateStr.trim());
              date.setHours(0, 0, 0, 0); // Define o horário da data para 00:00:00
              return date;
            });
      
            const latestDate = availabilityDates.sort((a, b) => b - a)[0];
      
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Define o horário da data atual para 00:00:00
      
            // Compara as datas levando em consideração apenas o dia
            return currentDate > latestDate;
          } catch (error) {
            console.error("Erro ao processar availability:", error);
            return false;
          }
        };
      
        if (props.data.type === '3' && props.data.suppliers) {
          const suppliersNegados = props.data.suppliers.filter(async (order) => {
            const isExpired = isDateExpired(order.availability);
            if (!order.winner && (isExpired && order.active) && (parseFloat(order.userId) !== parseFloat(id))) {
              //console.log("ORDER:", order);
              const noPosition = {
                id: order.id,
                winner: false,
                active: false,
                status: 'REJEITADO',
                currentLanguage: currentLanguage
              };
              try {
                await rejectResponseSuppliers(noPosition);
                if(props.refresh){
                  props.refresh(Date.now());
                }
              } catch (err) {
                console.error(err);
              }
            }
          });
        } else if (props.data.type === '2' && props.data.services) {
          const servicesNegados = props.data.services.filter(async (order) => {
            const isExpired = isDateExpired(order.availability);
            if (!order.winner && (isExpired && order.active) && (parseFloat(order.userId) !== parseFloat(id))) {
              //console.log("ORDER:", order);
              const noPosition = {
                id: order.id,
                winner: false,
                active: false,
                status: 'REJEITADO',
                currentLanguage: currentLanguage
              };
              try {
                await rejectResponseProviders(noPosition);
                if(props.refresh){
                  props.refresh(Date.now());
                }
              } catch (err) {
                console.error(err);
              }
            }
          });
        }
    };

    function getStatus() {

        const parseDate = (dateString) => {
          // Tenta primeiro o formato padrão ISO (YYYY-MM-DD)
          let parsedDate = new Date(dateString);
          
          // Se o resultado for inválido, tenta converter para um formato alternativo
          if (isNaN(parsedDate)) {
              const [day, month, year] = dateString.split('/');
              if (day && month && year) {
                  parsedDate = new Date(`${year}-${month}-${day}`);
              }
          }
          return parsedDate;
        };

        if (props.data.services && props.data.services.length > 0) {
            const service = props.data.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                if (service.id === 128) console.log('ID', service.id);
                if (service.active === true && service.winner === false) {
                    if (service.id === 128) console.log('ANALISE', service.id);
                    return (<span className="badge bg-warning py-1">{t('ANÁLISE')}</span>);                    
                } else if (service.active === false && service.winner === false) {
                    if (service.id === 128) console.log('CANCELADO', service.id);
                    return (<span className="badge bg-info py-1">{t('CANCELADO')}</span>);
                } else if (service.active === false && service.winner === true && service.raitherDay && parseDate(service.raitherDay).getTime() > Date.now()) {
                    if (service.id === 128) {
                    console.log('RAITHERDAY', new Date(service.raitherDay).getTime());
                    console.log('AGORA', Date.now());
                    console.log('AGENDADO', service.id); }
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else if (service.active === false && service.winner === true && service.raitherDay && parseDate(service.raitherDay).getTime() < Date.now()) {
                  if (service.id === 128) {
                    console.log('RAITHERDAY', new Date(service.raitherDay).getTime());
                    console.log('AGORA', Date.now());
                    console.log('FINALIZADO', service.id);}
                    return (<span className="badge bg-success py-1">{t('FINALIZADO')}</span>);
                }
            }
        } else if (props.data.suppliers && props.data.suppliers.length > 0) {
            const supplier = props.data.suppliers.find(item => parseFloat(id) === parseFloat(item.userId));
            if (supplier) {
                if (supplier.active === true && supplier.winner === false) {
                    return (<span className="badge bg-warning py-1">{t('ANÁLISE')}</span>);                    
                } else if (supplier.active === false && supplier.winner === false) {
                    return (<span className="badge bg-info py-1">{t('CANCELADO')}</span>);
                } else if (supplier.active === false && supplier.winner === true && supplier.raitherDay && new Date(supplier.raitherDay).getTime() > Date.now()) {
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else if (supplier.active === false && supplier.winner === true && supplier.raitherDay && new Date(supplier.raitherDay).getTime() < Date.now()) {
                    return (<span className="badge bg-success py-1">{t('FINALIZADO')}</span>);
                }
            }
        } else if (props.data.favoriteId !== 0) {
            return (<span className="badge bg-purple py-1">{t('EXCLUSIVO')}</span>); 
        }

        if (props.data.status === 'FINALIZADO') {
            console.log('PASSOU AQUI FINALIZADO');
            if (props.data.suppliers && props.data.suppliers.length > 0) {
              const cliente = props.data.suppliers.find(item => parseFloat(id) === parseFloat(item.clienteId) && item.winner === true);
            
              if (cliente) {
                // Verifica se o raitherDay existe e se está no futuro
                if (cliente.raitherDay && new Date(cliente.raitherDay).getTime() > Date.now()) {
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else {
                    return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
                }
              } 
            } else if (props.data.services && props.data.services.length > 0) {
              const cliente = props.data.services.find(item => parseFloat(id) === parseFloat(item.clienteId) && item.winner === true);
            
              if (cliente) {
                // Verifica se o raitherDay existe e se está no futuro
                if (cliente.raitherDay && new Date(cliente.raitherDay).getTime() > Date.now()) {
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else {
                    return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
                }
              }
            }
        }
        return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
      
          //console.log('PASSO AKI');
          
    };

    /*function getStatus() {
      const findUserStatus = (items) => {
          return items.find(item => parseFloat(id) === parseFloat(item.userId));
      };
  
      const renderBadge = (status, label) => {
          const badgeClass = {
              'ANÁLISE': 'bg-warning',
              'CANCELADO': 'bg-info',
              'AGENDADO': 'bg-success',
              'FINALIZADO': 'bg-success',
              'EXCLUSIVO': 'bg-purple',
              'ABERTO': 'bg-info',
          };
          return <span className={`badge ${badgeClass[status]} py-1`}>{t(label)}</span>;
      };
  
      const parseDate = (dateString) => {
          // Tenta primeiro o formato padrão ISO (YYYY-MM-DD)
          let parsedDate = new Date(dateString);
          
          // Se o resultado for inválido, tenta converter para um formato alternativo
          if (isNaN(parsedDate)) {
              const [day, month, year] = dateString.split('/');
              if (day && month && year) {
                  parsedDate = new Date(`${year}-${month}-${day}`);
              }
          }
          return parsedDate;
      };
  
      const checkServiceOrSupplierStatus = (item) => {
          if (item.active === true && item.winner === false) {
              return renderBadge('ANÁLISE', 'ANÁLISE');
          } else if (item.active === false && item.winner === false) {
              return renderBadge('CANCELADO', 'CANCELADO');
          } else if (item.active === false && item.winner === true) {
              const raitherDayTime = parseDate(item.raitherDay).getTime();
              const now = Date.now();
              if (item.raitherDay && raitherDayTime > now) {
                  return renderBadge('AGENDADO', 'AGENDADO');
              } else if (item.raitherDay && raitherDayTime < now) {
                  return renderBadge('FINALIZADO', 'FINALIZADO');
              }
          }
          return null;
      };
  
      // Verifica serviços e fornecedores
      if (props.data.services && props.data.services.length > 0) {
          const service = findUserStatus(props.data.services);
          if (service) return checkServiceOrSupplierStatus(service);
      }
  
      if (props.data.suppliers && props.data.suppliers.length > 0) {
          const supplier = findUserStatus(props.data.suppliers);
          if (supplier) return checkServiceOrSupplierStatus(supplier);
      }
  
      // Verifica se é favorito
      if (props.data.favoriteId !== 0) {
          return renderBadge('EXCLUSIVO', 'EXCLUSIVO');
      }
  
      // Verifica status de finalização
      if (props.data.status === 'FINALIZADO') {
          console.log('PASSOU AQUI FINALIZADO');
          const cliente = (props.data.suppliers || props.data.services || []).find(item => parseFloat(id) === parseFloat(item.clienteId));
  
          if (cliente) {
              const raitherDayTime = parseDate(cliente.raitherDay).getTime();
              if (cliente.raitherDay && raitherDayTime > Date.now()) {
                  return renderBadge('AGENDADO', 'AGENDADO');
              }
          }
      }
  
      // Retorno padrão
      return renderBadge(props.data.status || 'ABERTO', props.data.status || 'ABERTO');
  }*/



    // Função para calcular a cor de fundo com base na quantidade de serviços ou fornecedores
    function calculateBackgroundColor(servicesCount, suppliersCount) {
        const totalCount = servicesCount + suppliersCount;
        if (totalCount === 0) {
            return 'rgba(255, 255, 255, 1)'; // Branco para sem respostas
        } else {
            const intensity = Math.min(totalCount * 20, 255); // Quanto mais serviços ou fornecedores, mais intenso o azul
            return `rgba(173, 216, 230, ${intensity / 255})`; // Azul claro com intensidade variável
        }
    }

    const calcularNegados = () => {
        const isDateExpired = (availabilityString) => {
          if (!availabilityString) {
            return false; // Se não houver disponibilidade, não considera expirado
          }
      
          // Tenta fazer o parsing correto da data
          try {
            const availabilityDates = availabilityString.split(',').map(dateStr => {
              const date = new Date(dateStr.trim());
              date.setHours(0, 0, 0, 0); // Define o horário da data para 00:00:00
              return date;
            });
      
            const latestDate = availabilityDates.sort((a, b) => b - a)[0];
      
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Define o horário da data atual para 00:00:00
      
            // Compara as datas levando em consideração apenas o dia
            return currentDate > latestDate;
          } catch (error) {
            console.error("Erro ao processar availability:", error);
            return false;
          }
        };
      
        if (props.data.type === '3' && props.data.suppliers) {
          const suppliersNegados = props.data.suppliers.filter(order => {
            const isExpired = isDateExpired(order.availability);
    
    
    
            return (!order.winner && !order.active) || (!order.winner && isExpired) || order.negado;
          }).length;
          return props.data.suppliers.length - suppliersNegados;
        } else if (props.data.services) {
          const servicesNegados = props.data.services.filter(order => {
            const isExpired = isDateExpired(order.availability);
    
    
            
            return (!order.winner && !order.active) || (!order.winner && isExpired) || order.denied;
          }).length;
          return props.data.services.length - servicesNegados;
        }
      
        return 0; // Retorna 0 se não houver suppliers ou services
    };

    //const servicesCount = props.data.services ? props.data.services.length : 0;
    //const suppliersCount = props.data.suppliers ? props.data.suppliers.length : 0;
    //const backgroundColor = calculateBackgroundColor(servicesCount, suppliersCount);

    const servicesCount = props.data.services ? calcularNegados() : 0;
    const suppliersCount = props.data.suppliers ? calcularNegados() : 0;
    const backgroundColor = calculateBackgroundColor(servicesCount, suppliersCount);

    return (
        <>
        { isLargeScreen 
            ? ( 
                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem", backgroundColor }} onClick={handleRowClick}>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>#{props.data.id}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{formatarDataHora()}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        {
                            props.myType === '1'
                                ? props.data.type === '3' ? `${t('Fornecedor')}/${t(props.data.categoriaF)}` : `${t('Serviço')}/${t(props.data.categoriaS)}`
                                : props.data.type === '3' ? `${t(props.data.categoriaF)}` : `${t(props.data.categoriaS)}`
                        }
                    </td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", verticalAlign: "middle" }}>
                        {props.myType === '1' && props.data.active === true 
                            ? props.data.favoriteId !== 0 
                                ? <span className="badge bg-purple py-1">{t('DIRETO')}</span>
                                : calcularNegados() // props.data.type === '3' 
                                    // ? props.data.suppliers.length  - negados
                                    // : props.data.services.length  - negados
                            : <a
                            id={"view" + props.data.id}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalViewOrder"
                            onClick={props.onClick}
                          >
                            {getStatus()}
                          </a>    
                        }
                    </td>
                </tr>
            ): (
                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem", backgroundColor }} onClick={handleRowClick}>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>#{props.data.id}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{formatarDataHora()}</td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        {
                            props.myType === '1'
                                ? props.data.type === '3' ? `${t('Fornecedor')}/${props.data.categoriaF}` : `${t('Serviço')}/${props.data.categoriaS}`
                                : props.data.type === '3' ? `${props.data.categoriaF}` : `${props.data.categoriaS}`
                        }
                    </td>
                    <td className="text-gray-900" style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "center", verticalAlign: "middle" }}>
                        {props.myType === '1' && props.data.active === true
                            ? props.data.favoriteId !== 0 
                                ? <span className="badge bg-purple py-1">{t('DIRETO')}</span>
                                : calcularNegados() // props.data.type === '3' 
                                    // ? props.data.suppliers.length  - negados
                                    // : props.data.services.length  - negados
                            : <a
                              id={"view" + props.data.id}
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#modalViewOrder"
                              onClick={props.onClick}
                            >
                              {getStatus()}
                            </a>
                        }
                    </td>
                </tr>
            )
        }
        </>
    );
}

export default OrdersRow;
