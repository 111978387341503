import React, { useState, useEffect } from 'react';
import { updateFavorite, chkFavorite } from '../../services/OrdersService';
import { rejectResponseProviders } from '../../services/OrdersService';
import { getPriority } from '../../services/SettingsService';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * props:
 * - data
 * - onClick
 */
function ProvidersRow(props) {
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    const id = localStorage.getItem('id');

    const [vencedores, setVencedores] = useState([]);
    const [favorite, setFavorite] = useState({});
    const [agendado, setAgendado] = useState(false);
    const [priority, setPriority] = useState(null);

    /*useEffect(() => {
        if (vencedores.length > 0) {
            vencedores.forEach(objeto => {
                const objetoReduzido = {
                    userId: objeto.clienteId,
                    providerId: objeto.userId,
                    name: objeto.name,
                    sobrenamo: objeto.sobrenamo,
                    job: objeto.job,
                    cnpj: objeto.cnpj,
                    type: objeto.type,
                    categoriaS: objeto.categoriaS,
                    categoriaF: objeto.categoriaF,
                    phone: objeto.phone
                };
                if (parseFloat(id) === parseFloat(objetoReduzido.userId)){
                    chkFavorite(objetoReduzido)
                        .then(result => {
                            if (result === true) {
                                setFavorite({
                                    ...objetoReduzido,
                                    isFavorite: true
                                });
                            } else {
                                setFavorite({
                                    ...objetoReduzido,
                                    isFavorite: false
                                });
                            }
                        })
                        .catch(err => console.error(err.response ? err.response.data : err.message));
                }
            });
        }    
    }, [vencedores]);*/

    useEffect(() => {
        const processVencedores = async () => {
            if (vencedores.length > 0) {
                await Promise.all(vencedores.map(async objeto => {
                    const objetoReduzido = {
                        userId: objeto.clienteId,
                        providerId: objeto.userId,
                        name: objeto.name,
                        sobrenamo: objeto.sobrenamo,
                        job: objeto.job,
                        cnpj: objeto.cnpj,
                        type: objeto.type,
                        categoriaS: objeto.categoriaS,
                        categoriaF: objeto.categoriaF,
                        phone: objeto.phone
                    };
                    
                    if (parseFloat(id) === parseFloat(objetoReduzido.userId)) {
                        const promises = [
                            chkFavorite(objetoReduzido),
                            getPriority(parseFloat(objetoReduzido.providerId))
                        ];
    
                        const [favoriteResult, priorityResult] = await Promise.allSettled(promises);
    
                        if (favoriteResult.status === "fulfilled") {
                            setFavorite({
                                ...objetoReduzido,
                                isFavorite: favoriteResult.value
                            });
                        } else {
                            console.error(favoriteResult.reason);
                        }
    
                        if (priorityResult.status === "fulfilled") {
                            console.log(objetoReduzido.providerId, priorityResult.value);
                            setPriority(priorityResult.value);
                        } else {
                            console.error(priorityResult.reason);
                        }
                    }
                }));
            }
        };
    
        processVencedores();
    }, [vencedores]);

    function onFavoriteClick(event) {
        const updatedFavorite = { ...favorite, isFavorite: !favorite.isFavorite };
        updateFavorite(updatedFavorite)
            .then(result => setFavorite(updatedFavorite))
            .catch(err => console.error(err.response ? err.response.data : err.message));
    }

    useEffect(() => {
        if (props.data.services && props.data.services !== null && props.data.services.length > 0) {
            const vencedores = props.data.services.filter(order => order.winner === true);
            setVencedores(vencedores);
        }
        if (props.data.status === 'FINALIZADO') {
            if (props.data.services && props.data.services.length > 0 ) {
              const cliente = props.data.services.find(item => parseFloat(id) === parseFloat(item.clienteId) && item.winner === true && item.raitherDay && new Date(item.raitherDay).getTime() > Date.now());
            
              if (cliente) {
                    setAgendado(true);
                } else {
                    setAgendado(false);
                }
            }
        }
        excludeService();
    }, [props.data.services]);

    function handleRowClick() {
        if (props.data.services){
            props.onViewProvider(props.data.services);
        } else {
            props.onViewProvider({});
        }
    }

    function getStatus(status) {
        let className;
        switch (status) {
            case 'ABERTO': className = "badge bg-info py-1"; break;
            case 'FINALIZADO': className = "badge bg-success py-1"; break;
            case 'CANCELADO': className = "badge bg-info py-1"; break;
            default: className = "badge bg-warning py-1"; break;
        }
        return (<span className={className}>{status.split('_')[0]}</span>);
    }

    function formatarDataHora() {
        var data_hora = new Date(props.data.createdAt);
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1;
        var ano = data_hora.getFullYear() % 100;
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
        var data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
        return data_formatada;
    }

    function getStatus() {
        if (props.data.services) {
            const service = props.data.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                if (service.active === true && service.winner === false) {
                    return (<span className="badge bg-warning py-1">{t('ANÁLISE')}</span>);                    
                } else if (service.active === false && service.winner === false) {
                    return (<span className="badge bg-info py-1">{t('CANCELADO')}</span>);
                } else if (service.active === false && service.winner === true && service.raitherDay && new Date(service.raitherDay).getTime() > Date.now()) {
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else if (service.active === false && service.winner === true && service.raitherDay && new Date(service.raitherDay).getTime() < Date.now()) {
                    return (<span className="badge bg-success py-1">{t('FINALIZADO')}</span>);
                }
            }
        } else if (props.data.favoriteId !== 0) {
            return (<span className="badge bg-purple py-1">{t('EXCLUSIVO')}</span>); 
        }
        if (props.data.status === 'FINALIZADO') {
            if (props.data.services && props.data.services.length > 0) {
              const cliente = props.data.services.find(item => parseFloat(id) === parseFloat(item.clienteId) && item.winner === true);
            
              if (cliente) {
                // Verifica se o raitherDay existe e se está no futuro
                if (cliente.raitherDay && new Date(cliente.raitherDay).getTime() > Date.now()) {
                    return (<span className="badge bg-success py-1">{t('AGENDADO')}</span>);
                } else {
                    return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
                }
              }
            }
        }
        return (<span className="badge bg-info py-1">{props.data.status ? t(props.data.status) : t('ABERTO')}</span>);
    }

    function checkServiceStatus() {
        if (props.data.services) {
            const service = props.data.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                if (service.active === true && service.winner === false) {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm0 8.625a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM15.375 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 10.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z" clipRule="evenodd" />
                        </svg>
                    );
                } else if (service.active === false && service.winner === false) {
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                            <path fillRule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clipRule="evenodd" />
                        </svg>
                    );
                } else if (service.active === false && service.winner === true && service.raitherDay && new Date(service.raitherDay).getTime() > Date.now()) { //AGENDADO
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                            <path d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" />
                            <path fillRule="evenodd" d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z" clipRule="evenodd" />
                        </svg>
                    );
                } else if (service.active === false && service.winner === true && service.raitherDay && new Date(service.raitherDay).getTime() < Date.now()) { //FINALIZADO
                    return (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                            <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
                        </svg>
                    );
                }
            } else if (props.data.canceled) {
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                        <path fillRule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clipRule="evenodd" />
                    </svg>
                );
            }
            if (props.data.status === 'FINALIZADO') {
                if (props.data.services && props.data.services.length > 0) {
                  const cliente = props.data.services.find(item => parseFloat(id) === parseFloat(item.clienteId) && item.winner === true);
                
                  if (cliente) {
                    // Verifica se o raitherDay existe e se está no futuro
                    if (cliente.raitherDay && new Date(cliente.raitherDay).getTime() > Date.now()) {
                        return (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                <path d="M12 11.993a.75.75 0 0 0-.75.75v.006c0 .414.336.75.75.75h.006a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75H12ZM12 16.494a.75.75 0 0 0-.75.75v.005c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H12ZM8.999 17.244a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.006ZM7.499 16.494a.75.75 0 0 0-.75.75v.005c0 .414.336.75.75.75h.005a.75.75 0 0 0 .75-.75v-.005a.75.75 0 0 0-.75-.75H7.5ZM13.499 14.997a.75.75 0 0 1 .75-.75h.006a.75.75 0 0 1 .75.75v.005a.75.75 0 0 1-.75.75h-.006a.75.75 0 0 1-.75-.75v-.005ZM14.25 16.494a.75.75 0 0 0-.75.75v.006c0 .414.335.75.75.75h.005a.75.75 0 0 0 .75-.75v-.006a.75.75 0 0 0-.75-.75h-.005ZM15.75 14.995a.75.75 0 0 1 .75-.75h.005a.75.75 0 0 1 .75.75v.006a.75.75 0 0 1-.75.75H16.5a.75.75 0 0 1-.75-.75v-.006ZM13.498 12.743a.75.75 0 0 1 .75-.75h2.25a.75.75 0 1 1 0 1.5h-2.25a.75.75 0 0 1-.75-.75ZM6.748 14.993a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" />
                                <path fillRule="evenodd" d="M18 2.993a.75.75 0 0 0-1.5 0v1.5h-9V2.994a.75.75 0 1 0-1.5 0v1.497h-.752a3 3 0 0 0-3 3v11.252a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V7.492a3 3 0 0 0-3-3H18V2.993ZM3.748 18.743v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5h-13.5a1.5 1.5 0 0 1-1.5-1.5Z" clipRule="evenodd" />
                            </svg>
                        );
                    } else {
                        return (
                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                            </svg>
                        );
                    }
                  }
                }
            }
        } 
        return (
            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
            </svg>
        );
    }

    const excludeService = async () => {

        const isDateExpired = (availabilityString) => {
          if (!availabilityString) {
            return false; // Se não houver disponibilidade, não considera expirado
          }
      
          // Tenta fazer o parsing correto da data
          try {
            const availabilityDates = availabilityString.split(',').map(dateStr => {
              const date = new Date(dateStr.trim());
              date.setHours(0, 0, 0, 0); // Define o horário da data para 00:00:00
              return date;
            });
      
            const latestDate = availabilityDates.sort((a, b) => b - a)[0];
      
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Define o horário da data atual para 00:00:00
      
            // Compara as datas levando em consideração apenas o dia
            return currentDate > latestDate;
          } catch (error) {
            console.error("Erro ao processar availability:", error);
            return false;
          }
        };
      
        if (props.data.type === '2' && props.data.services) {
          const servicesNegados = props.data.services.filter(async (order) => {
            const isExpired = isDateExpired(order.availability);
            if (!order.winner && (isExpired && order.active) && (parseFloat(order.userId) !== parseFloat(id))) {
              //console.log("ORDER:", order);
              const noPosition = {
                id: order.id,
                winner: false,
                active: false,
                status: 'REJEITADO',
                currentLanguage: currentLanguage
              };
              try {
                await rejectResponseProviders(noPosition);
                if(props.refresh){
                  props.refresh(Date.now());
                }
              } catch (err) {
                console.error(err);
              }
            }
          });
        }
    };

    // Função para calcular a cor de fundo com base na quantidade de serviços
    function calculateBackgroundColor(servicesCount) {
        if (servicesCount === 0) {
            return 'rgba(255, 255, 255, 1)'; // Branco para sem respostas
        } else {
            const intensity = Math.min(servicesCount * 20, 255); // Quanto mais serviços, mais intenso o azul
            return `rgba(173, 216, 230, ${intensity / 255})`; // Azul claro com intensidade variável
        }
    }

    const servicesCount = props.data.services ? props.data.services.length : 0;
    const backgroundColor = calculateBackgroundColor(servicesCount);

    return (
        isLargeScreen ? (
            <tr onClick={handleRowClick} style={{ backgroundColor: backgroundColor }}>
                <td><span className="fw-normal">#{props.data.id}</span></td>
                <td><span className="fw-normal">{formatarDataHora()}</span></td>
                <td><span className="fw-normal">
                  {props.data.type === '3' ? t(props.data.categoriaF) : t(props.data.categoriaS)}
                </span></td>
                <td>{getStatus()}</td>
                <td>
                    <button
                        id={"view" + props.data.id}
                        type="button"
                        className="btn btn-info btn-xs"
                        data-bs-toggle="modal"
                        data-bs-target="#modalViewOrder"
                        onClick={props.onClick}
                    >
                        {checkServiceStatus()}
                    </button>
                    <b> </b>
                    {props.myType !== '2' && vencedores.length > 0 && !props.data.active && props.data.services.find(item => parseFloat(id) !== parseFloat(item.userId)) || parseFloat(id) === parseFloat(props.data.userId) && vencedores.length > 0 && !props.data.active
                        ? <React.Fragment>
                            {!agendado && ( <React.Fragment>
                                <button
                                    id={"rating" + props.data.id}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalViewOrder"
                                    title={t('AVALIAÇÃO')}
                                    onClick={props.onStarClick}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                        <path
                                            fillRule="evenodd"
                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 0 0-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634Zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 0 1-.189-.866c0-.298.059-.605.189-.866Zm2.023 6.828a.75.75 0 1 0-1.06-1.06 3.75 3.75 0 0 1-5.304 0 .75.75 0 0 0-1.06 1.06 5.25 5.25 0 0 0 7.424 0Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                <b> </b>
                                <button
                                    id={"star" + props.data.id}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    title={priority > 0 ? t('FAVORITOS') : t('INDISPONÍVEL')}
                                    onClick={onFavoriteClick}
                                    disabled = {priority === 0}
                                >
                                    <svg className="icon icon-xs" fill={favorite.isFavorite ? "yellow" : "white"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                </button>
                            </React.Fragment> )}    
                            <b> </b>
                            <Link to={`/chat/${vencedores[0].userId}`}>
                                <button
                                    id={"vencedor"}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    title="CHAT"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon icon-xs">
                                        <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                        <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                                    </svg>
                                </button>
                            </Link>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>  
                    }
                </td>
            </tr>
        ) : (
            <tr onClick={handleRowClick} style={{ backgroundColor: backgroundColor, paddingLeft: "1rem", paddingRight: "1rem" }}>
                <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}><span className="fw-normal">
                  {props.data.type === '3' ? t(props.data.categoriaF) : t(props.data.categoriaS)}
                </span></td>
                <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{getStatus()}</td>
                <td style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <button
                        id={"view" + props.data.id}
                        type="button"
                        className="btn btn-info btn-xs"
                        data-bs-toggle="modal"
                        data-bs-target="#modalViewOrder"
                        onClick={props.onClick}
                    >
                        {checkServiceStatus()}
                    </button>
                    <b> </b>
                    {props.myType !== '2' && vencedores.length > 0 && !props.data.active && props.data.services.find(item => parseFloat(id) !== parseFloat(item.userId)) || parseFloat(id) === parseFloat(props.data.userId) && vencedores.length > 0 && !props.data.active
                        ? <React.Fragment>
                            {!agendado && ( <React.Fragment>
                                <button
                                    id={"rating" + props.data.id}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalViewOrder"
                                    title={t('AVALIAÇÃO')}
                                    onClick={props.onStarClick}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                        <path
                                            fillRule="evenodd"
                                            d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 0 0-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634Zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 0 1-.189-.866c0-.298.059-.605.189-.866Zm2.023 6.828a.75.75 0 1 0-1.06-1.06 3.75 3.75 0 0 1-5.304 0 .75.75 0 0 0-1.06 1.06 5.25 5.25 0 0 0 7.424 0Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                <b> </b>
                                <button
                                    id={"star" + props.data.id}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    title={priority > 0 ? t('FAVORITOS') : t('INDISPONÍVEL')}
                                    onClick={onFavoriteClick}
                                    disabled = {priority === 0}
                                >
                                    <svg className="icon icon-xs" fill={favorite.isFavorite ? "yellow" : "white"} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                </button>
                            </React.Fragment> )}
                            <b> </b>
                            <Link to={`/chat/${vencedores[0].userId}`}>
                                <button
                                    id={"vencedor"}
                                    className="btn btn-sm btn-info btn-xs animate-up-2"
                                    title="CHAT"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="icon icon-xs">
                                        <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                                        <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
                                    </svg>
                                </button>
                            </Link>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>
                    }
                </td>
            </tr>
        )
    );
}

export default ProvidersRow;
