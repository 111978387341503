import axios from './BaseService';

const SETTINGS_URL = `${process.env.REACT_APP_API_URL}/settings` || 'http://localhost:3001/settings';

export async function getSettings() {
    const response = await axios.get(SETTINGS_URL);
    return response.data;
}

export async function getName() {
    const settingsUrl = `${SETTINGS_URL}/name`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function getSite(itemId) {
    const settingsUrl = `${SETTINGS_URL}/site/${itemId}`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function getDocs(itemId) {
    const settingsUrl = `${SETTINGS_URL}/docs/${itemId}`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function getExperiences(itemId) {
    const settingsUrl = `${SETTINGS_URL}/experience/${itemId}`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function getRedes(itemId) {
    const settingsUrl = `${SETTINGS_URL}/redessociais/${itemId}`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function whoIAm() {
    const settingsUrl = `${SETTINGS_URL}/type`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function checkSettings() {
    const settingsUrl = `${SETTINGS_URL}/check`;
    const response = await axios.get(settingsUrl);
    return response.data;
}

export async function updateSettings(settings) {
    console.log(settings);
    const response = await axios.patch(SETTINGS_URL, settings);
    return response.data;
}

// Função para realizar o upload da imagem
export async function uploadAvatar(formData) {
    const settingsUrl = `${SETTINGS_URL}/avatar`;
    const response = await axios.post(settingsUrl, formData)
    return response.data;
}

// Função para realizar o upload da imagem
export async function uploadBrand(formData) {
    const settingsUrl = `${SETTINGS_URL}/brand`;
    const response = await axios.post(settingsUrl, formData)
    return response.data;
}

// Função para realizar o upload da imagem
export async function getAvatar() {
    const settingsUrl = `${SETTINGS_URL}/avatar`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Função para realizar o upload da imagem
export async function getBrand() {
    const settingsUrl = `${SETTINGS_URL}/brand`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

// Função para realizar o upload da imagem
export async function getFavorites() {
    const settingsUrl = `${SETTINGS_URL}/favorites`;
    const response = await axios.get(settingsUrl)
    return response.data;
}

export async function getPriority(itemId){
    const settingsUrl = (`${SETTINGS_URL}/priority/${itemId}`);
    const response = await axios.get(settingsUrl);
    return response.data;
};