import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import './ViewProvidersModal.css'; // Importe o arquivo CSS aqui
import {
  endCreateProvider,
  excludeOrder,
  endOrderProvider,
  cancelOrder,
  saveResponseProviders,
  getImages
} from '../../services/OrdersService';
import { getSettings, getDocs } from '../../services/SettingsService';
import GoogleMapsWidget from '../../components/GoogleMaps/GoogleMapsWidget';
import SelectHour from '../../components/SelectHour/SelectHour';
import FornecedorCalendar from '../../components/FornecedorCalendar/FornecedorCalendar';
import ButtonRow from './ButtonRow';
import InfoWinner from './InfoWinner';
import InfoProvider from './InfoProvider';
import StarRatingComponent from 'react-star-rating-component';
import { createRating, getAverageRating } from '../../services/RatingService';
import { getAvatar, getBrand } from '../../services/ChatService';
import Avatar from '../../components/Avatar/Avatar';
import { useTranslation } from 'react-i18next';

function ViewProvidersModal(props) {

    const history = useHistory();
    const { t, i18n } = useTranslation();
    
    // Adicione esta linha para pegar o idioma atual
    const currentLanguage = i18n.language;
    const id = localStorage.getItem('id');

    const DEFAULT_ORDER = { symbol: '' };
    const DEFAULT_FEED = { comments: '' };
    const DEFAULT_RESPONSE = {
        resposta: '',
        userId: '',
        real: '',
        voluntario: false,
        type: '',
        hour: '',
        name: '',
        sobrenamo: '',
        street: '',
        state: '',
        country: '',
        city: '',
        cep: '',
        number: '',
        neighborhood: '',
        job: '',
        cnpj: '',
        categoriaS: '',
        categoriaF: '',
        phone: '',
        description: '',
        active: true,
        winner: false
    };

    const btnClose = useRef('');
    const printRef = useRef();

    const [order, setOrder] = useState(DEFAULT_ORDER);

    const [feed, setFeed] = useState(DEFAULT_FEED);

    const [response, setResponse] = useState(DEFAULT_RESPONSE);

    const [error, setError] = useState('');

    const [imagesBase64, setImagesBase64] = useState([]);

    const [isImageVisible, setIsImageVisible] = useState(false);

    const [isMapVisible, setIsMapVisible] = useState(false);

    const [showProposta, setShowProposta] = useState(false);
    const [vencedores, setVencedores] = useState({});

    const [viewWinner, setViewWinner] = useState({});
    const [isWinnerVisible, setIsWinnerVisible] = useState(false);

    const [availability, setAvailability] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);

    const [imagesRe, setReImages] = useState([]);
    const [arrayImages, setArrayImages] = useState([]);

    const [avatar, setAvatar] = useState(null);

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const [showLimite, setShowLimite] = useState(false);

    const [docs, setDocs] = useState('');

    const [agendado, setAgendado] = useState(false);

    useEffect(() => {
        getSettings()
            .then(result => {
                setResponse(result);
                setResponse(prevState => ({ ...prevState, voluntario: false }));
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }, []);

    useEffect(() => {
        if (props.data) {
            setOrder(props.data);
            setIsWinnerVisible(false);
            setIsImageVisible(false);
            setIsMapVisible(false);
            setShowProposta(false);
            setReImages([]);
            setVencedores({});
            setAgendado(false);

            if (props.data.services && props.data.services !== null && props.data.services.length > 0) {
                const vencedores = props.data.services.filter(order => order.winner === true);
                setVencedores(vencedores);

                // Acessa o raitherDay dos fornecedores
                const fornecedorComRaitherDay = props.data.services.find(item => item.raitherDay);
                if (fornecedorComRaitherDay && new Date(fornecedorComRaitherDay.raitherDay).getTime() > Date.now()) {
                    setAgendado(true);
                    //console.log('agendado true');
                } else {
                    setAgendado(false);
                    //console.log('agendado false');
                }
            }
        }
    }, [props.data]);

    /*useEffect(() => {
        const fetchData = async () => {
            try {
                const avatarData = await getAvatar(order.userId);
                //console.log(avatarData);
                setAvatar(`data:image/png;base64,${avatarData}`);
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchData();
    }, [order.userId]);*/

    useEffect(() => {
        const fetchData = async () => {
          try {
            // Verifica se `vencedores` existe e tem itens, e o `response.id` é diferente do `vencedores[0].userId`
            if (vencedores && vencedores.length > 0 && response.id !== vencedores[0].userId) {
              // Faz as requisições em paralelo usando Promise.allSettled
              const results = await Promise.allSettled([
                getAvatar(vencedores[0].userId),
                getDocs(vencedores[0].userId)
              ]);
      
              const [avatarResult, docsResult] = results;
      
              // Verifica se `getAvatar` foi bem-sucedido e atualiza o estado
              if (avatarResult.status === 'fulfilled') {
                setAvatar(`data:image/png;base64,${avatarResult.value}`);
              }
      
              // Verifica se `getDocs` foi bem-sucedido e atualiza o estado
              if (docsResult.status === 'fulfilled') {
                setDocs(docsResult.value); // Atualizando corretamente `setDocs`
              }
            } else {
              // Se `vencedores` não estiver disponível, faz apenas a requisição de `getAvatar` para `order.userId`
              const avatarResult = await getAvatar(order.userId);
      
              if (avatarResult) {
                setAvatar(`data:image/png;base64,${avatarResult}`);
              }
            }
      
          } catch (error) {
            console.error(error);
          }
        };
      
        if (order.userId) fetchData();
      }, [order.userId, vencedores]);

    function getStatusClass(status) {
        switch (status) {
            case 'ABERTO': return "badge bg-info";
            case 'FINALIZADO': return "badge bg-success";
            case 'CANCELADO': return "badge bg-danger";
            default: return "badge bg-primary";
        }
    }

    function formatDate(dateString) {
        if (!dateString) return ''; // Retorna vazio se a data for null ou undefined
        
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    }

    function formatarDatas(dataString) {
        if (!dataString) return '';

        // Divide a string por vírgula para separar as datas
        const datas = dataString.split(',');
        
        // Mapeia cada data para o novo formato
        const datasFormatadas = datas.map(data => {
          const dataObj = new Date(data.trim());
          const dia = String(dataObj.getDate()).padStart(2, '0');
          const mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
          const ano = dataObj.getFullYear();
          return `${dia}/${mes}/${ano}`;
        });
      
        // Junta as datas formatadas de volta em uma string
        return datasFormatadas.join(', ');
    }

    function formatarDataHora() {
        var data_hora = new Date(order.createdAt);
        var dia = data_hora.getDate();
        var mes = data_hora.getMonth() + 1;
        var ano = data_hora.getFullYear() % 100;
        var hora = data_hora.getHours();
        var minutos = data_hora.getMinutes();
        var periodo = "AM";
        if (hora >= 12) {
            periodo = "PM";
            hora -= 12;
        }
        if (hora === 0) {
            hora = 12;
        }
        var data_formatada = dia + '/' + mes + '/' + ano + ', ' + hora + ':' + (minutos < 10 ? '0' : '') + minutos + ' ' + periodo;
        return data_formatada;
    }

    useEffect(() => {
        const modal = document.getElementById('modalViewOrder');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setOrder({ ...DEFAULT_ORDER });
        });
    }, []);

    function onImageReChange(event) {
        const files = event.target.files;
        setArrayImages(prevArray => [...prevArray, ...files]);
        if (files && files.length > 0) {
            const newImages = [];
            for (let i = 0; i < files.length; i++) {
                newImages.push(URL.createObjectURL(files[i]));
            }
            setReImages(prevImages => [...prevImages, ...newImages]);
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    function removeImage(index) {
        setReImages(prevImages => prevImages.filter((_, i) => i !== index));
        setArrayImages(prevArrayImages => prevArrayImages.filter((_, i) => i !== index));
    }

    function renderImageRePreviews() {
        return imagesRe.map((image, index) => (
            <div key={index} className="image-preview-container">
                <img src={image} alt={`Image ${index}`} className="image-preview" />
                <button type="button" className="btn-remove-image" onClick={() => removeImage(index)}>x</button>
            </div>
        ));
    }

    function openImageInNewWindow(imageSrc) {
        const newWindow = window.open();
        newWindow.document.write(`<img src="${imageSrc}" alt="Image" style="width: 100%;" />`);
        newWindow.document.title = "Image Preview";
    }
    
    function saveImage(imageSrc) {
        const link = document.createElement('a');
        link.href = imageSrc;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function chkResponse() {
        if (order.canceled) return true;
        if (order.services) {
            return order.services.some(item => parseFloat(id) === parseFloat(item.userId));
        }
        return false;
    }

    function checkProviderStatus() {
        if (order.services) {
            const service = order.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                if (service.active === true && service.winner === false) {
                    return (
                        <React.Fragment>                          
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <div className="col-md-8">                                            
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Estado-')} </b> <span className="badge bg-warning py-1">{t('ANÁLISE')}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Solicitação Nº')}:</b> {order.id}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Cliente')}:</b> {order.name} {order.sobrenamo}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Data da solicitação')}:</b> {formatarDataHora()}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Datas disponíveis')}:</b> {formatarDatas(service.availability)}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <b>{t('Horário')}:</b> {service.hour}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="print-hide col-md-4 d-flex flex-column align-items-center justify-content-center">
                                                {avatar && <Avatar imageUrl={avatar} alt="Avatar" size="big" frameStyle="double-shadow-black" />}
                                                <div><b>{order.name}</b></div>
                                            </div>
                                        </div>
                                    </div>      
                                    <div className="row">
                                        <div className="col-md-12 mb-3" style={{
                                            width: '100%',
                                            height: 'auto',
                                            minHeight: '20px',
                                            overflow: 'hidden',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word'
                                        }}>
                                            <b>{t('Solicitado')}:</b> {order.description}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3"style={{
                                            width: '100%',
                                            height: 'auto',
                                            minHeight: '20px',
                                            overflow: 'hidden',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word'
                                        }}>
                                            <b>{t('Minha propósta')}:</b> {service.resposta}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {   
                                            service.obs && service.obs !== null && service.obs !== 'null'
                                            ? <div className="col-md-12 mb-3" style={{
                                                width: '100%',
                                                height: 'auto',
                                                minHeight: '20px',
                                                overflow: 'hidden',
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <b>{t('Observação')}:</b> {service.obs}
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                    <div className="row">
                                        {   
                                            service.voluntario
                                            ? <div className="col-md-12 mb-3">
                                                <b>{t('Valor do Orçamento')}: </b> {t('Sem custos')}.
                                            </div>
                                            : service.real && service.real !== null && service.real !== 'null'
                                                ? <div className="col-md-12 mb-3">
                                                    <b>{t('Valor do Orçamento')}: </b> {service.real}
                                                </div>
                                                : <div className="col-md-12 mb-3">
                                                    <b>{t('Valor do Orçamento')}: </b> {t('Sem custos')}.
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                            {
                                order.images !== '[]'
                                    ? <button id={"viewPhotos" + order.id} type="button" className="print-hide btn btn-success animate-up-2 btn-xs" onClick={onImageChange}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                            <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    : <React.Fragment></React.Fragment>
                            }
                                <b> </b>
                                <button id={"viewMap" + order.id} type="button" className="print-hide btn btn-secondary animate-up-2 btn-xs" onClick={onMapChange}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                        <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <div className="col-md-12 mb-3">
                                {renderImagePreviews()}    
                                {renderMapPreviews()}
                            </div>                        
                        </React.Fragment>
                    );
                } else if (service.active === false && service.winner === false) {
                    return (
                        <React.Fragment>
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <b>{t('Solicitação Nº')}:</b> #{order.id}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <b>{t('Estado-')} </b> <span className="badge bg-danger py-1">{t('NEGADO')}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <b>{t('Nome')}:</b> {order.name}<b> </b>{order.sobrenamo}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <b>{t('Data de abertura')}:</b> {formatarDataHora()}
                                        </div>
                                    </div>
                                    {order.disponibility !== null
                                        ? <div className="row">
                                            <div className="col-6 mb-3">
                                                <b>{t('Data requisitada')}:</b> {order.disponibility}
                                            </div>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    <div className="row">
                                        <div className="col-md-12 mb-3" style={{
                                            width: '100%',
                                            height: 'auto',
                                            minHeight: '20px',
                                            overflow: 'hidden',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word'
                                        }}>
                                            <b>{t('Descrição')}: </b> {order.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onEndClick}>{t('Excluír')}</button>
                            </div>
                        </React.Fragment>
                    );
                } else if (service.active === false && service.winner === true) {
                    return (
                        <React.Fragment>                          
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-12 d-flex">
                                                <div className="col-md-8">                                            
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <b>{t('Estado-')} </b> <span className="badge bg-success py-1">{agendado ? t('AGENDADO') : t('APROVADO')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <b>{t('Solicitação Nº')}:</b> {order.id}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <b>{t('Cliente')}:</b> {order.name} {order.sobrenamo}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <b>{t('Data da solicitação')}:</b> {formatarDataHora()}
                                                        </div>
                                                    </div>
                                                    { service.raitherDay !== null
                                                        ? <div className="row">
                                                            <div className="col-12 mb-3">
                                                                <b>{t('Data para atendimento')}:</b> {formatDate(service.raitherDay)}
                                                            </div>
                                                            </div>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <b>{t('Horário')}:</b> {service.hour}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="print-hide col-md-4 d-flex flex-column align-items-center justify-content-center">
                                                    {avatar && <Avatar imageUrl={avatar} alt="Avatar" size="big" frameStyle="double-shadow-black" />}
                                                    <div><b>{order.name}</b></div>
                                                </div>
                                            </div>
                                        </div>      
                                        <div className="row">
                                            <div className="col-md-12 mb-3" style={{
                                                width: '100%',
                                                height: 'auto',
                                                minHeight: '20px',
                                                overflow: 'hidden',
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <b>{t('Solicitado')}:</b> {order.description}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3"style={{
                                                width: '100%',
                                                height: 'auto',
                                                minHeight: '20px',
                                                overflow: 'hidden',
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word'
                                            }}>
                                                <b>{t('Minha propósta')}:</b> {service.resposta}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {   
                                                service.obs && service.obs !== null && service.obs !== 'null'
                                                ? <div className="col-md-12 mb-3" style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    minHeight: '20px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'pre-wrap',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    <b>{t('Observação')}:</b> {service.obs}
                                                </div>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        </div>
                                        <div className="row">
                                            {   
                                                service.voluntario
                                                ? <div className="col-md-12 mb-3">
                                                    <b>{t('Valor do Orçamento')}: </b> {t('Sem custos')}.
                                                </div>
                                                : service.real && service.real !== null && service.real !== 'null'
                                                    ? <div className="col-md-12 mb-3">
                                                        <b>{t('Valor do Orçamento')}: </b> {service.real}
                                                    </div>
                                                    : <div className="col-md-12 mb-3">
                                                        <b>{t('Valor do Orçamento')}: </b> {t('Sem custos')}.
                                                    </div>
                                            }

                                        </div>

                                        <div className="row">
                                            {   
                                                order.phone
                                                    ? <div className="col-md-12 mb-3">
                                                        <b>{t('Contato')}: </b> {order.phone}
                                                     </div>
                                                    : <React.Fragment></React.Fragment>
                                            }

                                        </div>

                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <b>{t('CEP')}:</b> {order.cep}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <b>{t('Cidade')}:</b> {order.city}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <b>{t('Estado')}:</b> {order.state}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <b>{t('Bairro')}:</b> {order.neighborhood}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <b>{t('Rua')}:</b> {order.street}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <b>{t('Número')}:</b> {order.number}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 mb-3">
                                {
                                    order.images !== '[]'
                                        ? <button id={"viewPhotos" + order.id} type="button" className="print-hide btn btn-success animate-up-2 btn-xs" onClick={onImageChange}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                                <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        : <React.Fragment></React.Fragment>
                                }
                                    <b> </b>
                                    <button id={"viewMap" + order.id} type="button" className="print-hide btn btn-secondary animate-up-2 btn-xs" onClick={onMapChange}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                            <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-md-12 mb-3">
                                    {renderImagePreviews()}    
                                    {renderMapPreviews()}
                                </div>                        
                                <div className="modal-footer">
                                    <button type="button" className="print-hide btn btn-sm btn-purple" onClick={() => openInNewTab(`/chat/${order.userId}`)}>Chat</button>        
                                    <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onEndClick}>{t('Excluir')}</button>                    
                                </div>
                            </React.Fragment>
                    );
                }
            } else if (order.canceled) {
                return (
                    <React.Fragment>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <b>{t('Solicitação Nº')}:</b> #{order.id}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <b>{t('Estado-')} </b> <span className="badge bg-info py-1">{t('CANCELADO')}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <b>{t('Nome')}:</b> {order.name}<b> </b>{order.sobrenamo}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <b>{t('Data de abertura')}:</b> {formatarDataHora()}
                                    </div>
                                </div>
                                {order.disponibility !== null
                                    ? <div className="row">
                                        <div className="col-6 mb-3">
                                            <b>{t('Data requisitada')}:</b> {order.disponibility}
                                        </div>
                                    </div>
                                    : <React.Fragment></React.Fragment>
                                }
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <b>{t('Descrição')}: </b> {order.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {parseFloat(id) !== parseFloat(order.userId)
                            ? <div className="modal-footer">
                                <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onEndClick}>{t('Excluír')}</button>
                            </div>
                            : <div className="modal-footer">
                                <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onExcludeClick}>{t('Excluír')}</button>
                            </div>
                        }
                    </React.Fragment>
                );
            }
        }
        return 'Provedor não encontrado';
    }

    function onImageChange(event) {
        const images = JSON.parse(order.images);
        event.preventDefault();
        getImages(images)
            .then(result => {
                setImagesBase64(result);
                setIsImageVisible(!isImageVisible);
            })
            .catch(err => console.log(err));
    }

    function onWinnerClick(event) {
        event.preventDefault();
        const id = event.target.id.replace('vencedor', '');
        const sup = vencedores.filter(order => order.id == id);
        setIsWinnerVisible(!isWinnerVisible);
        setViewWinner({ ...sup });
    }

    function onInputChange(event) {
        const { id, value } = event.target;
        setResponse(prevState => ({ ...prevState, [id]: value }));
    }

    function onInputFeedChange(event) {
        const { id, value } = event.target;
        setFeed(prevState => ({ ...prevState, [id]: value }));
    }

    function onMapChange(event) {
        event.preventDefault();
        setIsMapVisible(!isMapVisible);
    }

    function onPropostaClick(event) {
        event.preventDefault();
        setShowProposta(!showProposta);
    }

    function renderImagePreviews() {
        if (isImageVisible) {
            return imagesBase64.map((image, index) => (
                <div key={index} className="image-preview-container">
                    <img src={`data:image/png;base64,${image}`} alt={`Image ${index}`} className="image-preview-h" />
                    <button type="button" className="print-hide btn btn-primary btn-xs" onClick={() => openImageInNewWindow(`data:image/png;base64,${image}`)}>Visualizar</button>
                    <b> </b>
                    <button type="button" className="print-hide btn btn-secondary btn-xs" onClick={() => saveImage(`data:image/png;base64,${image}`)}>Salvar</button>
                </div>
            ));
        }
    }

    function onHourChange(event) {
        setError('');
        setResponse(prevState => ({ ...prevState, hour: event.target.value }));
        setResponse(prevState => ({ ...prevState, active: true }));
        setResponse(prevState => ({ ...prevState, winner: false }));
    }

    function renderWinnerPreviews() {
        if (isWinnerVisible) {
            return <InfoWinner data={viewWinner} />;
        }
    }

    function renderMapPreviews() {
        if (isMapVisible && order.street) {
            return <GoogleMapsWidget localAddress={localAddress} destinationAddress={destinationAddress} />;
        }
    }

    const handleAvailabilityChange = availability => {
        setAvailability(availability);
    };

    const handleDateSelect = updatedSelectedDates => {
        setSelectedDates(updatedSelectedDates);
    };

    function onCheckboxChange(event) {
        setResponse(prevState => ({ ...prevState, voluntario: event.target.checked }));
        if (event.target.checked === true){
            setResponse(prevState => ({ ...prevState, real: '' }));
        }
    }

    function onCancelClick(event) {
        event.preventDefault();
        if (window.confirm(t('Tem certeza que deseja CANCELAR sua solicitação?'))) {
            
            const noPosition = {
                id: order.id,
                canceled: true,
                status: 'CANCELADO',
            };

            cancelOrder(noPosition)
                .then(result => {
                    setOrder(result);
                    btnClose.current.click();
                    if (props.onCancel) {
                        props.onCancel();
                    }
                })
                .catch(err => console.log(err));
        }
    }

    function onEndClick(event) {
        event.preventDefault();
        if (window.confirm(t('Tem certeza que deseja executar essa ação ?'))) {
            const service = order.services.find(item => parseFloat(id) === parseFloat(item.userId));
            if (service) {
                const position = {
                    orderId: service.orderId,
                    denied: true,
                };

                endOrderProvider(position)
                    .then(result => {
                        setOrder(result);
                        btnClose.current.click();
                        if (props.onFinishOrder) {
                            props.onFinishOrder();
                        }
                    })
                    .catch(err => console.log(err));
            } else {
                const position = {
                    orderId: order.id,
                    clienteId: order.userId,
                    userId: response.id,
                    name: response.name,
                    sobrenamo: response.sobrenamo,
                    denied: true,
                    status: 'CANCELADO/FINALIZADO'
                };

                endCreateProvider(position)
                    .then(result => {
                        setOrder(result);
                        btnClose.current.click();
                        if (props.onFinishOrder) {
                            props.onFinishOrder();
                        }
                    })
                    .catch(err => console.log(err));
            }
        }
    }

    function onExcludeClick(event) {
        
        event.preventDefault();
        if (window.confirm(t('Tem certeza que deseja EXCLUÍR ?'))) {
            const position = {
                id: order.id,
                excluded: true,
            };

            excludeOrder(position)
                .then(result => {
                    setOrder(result);
                    btnClose.current.click();
                    if (props.onExclude) {
                        props.onExclude();
                    }
                })
                .catch(err => console.log(err));
        }
    }

    function onSubmit(event) {
        event.preventDefault();

        if (!response.resposta || !response.hour) {
            setError(t('Por favor, preencha todos os campos obrigatórios.'));
            return;
        }

        if (Object.entries(availability).length === 0) {
            setError(t('Por favor, selecione no calendário sua disponibilidade.'));
            return;
        }

        if (!response.real && response.voluntario !== true) {
            setError(t('Por favor, preencha todos os campos obrigatórios.'));
            return;
        }

        const formData = new FormData();
        formData.append(`file`, arrayImages);
        arrayImages.forEach((file, index) => {
            formData.append(`file`, file);
        });
        formData.append('orderId', order.id);
        formData.append('clienteId', order.userId);
        formData.append('userId', response.id);
        formData.append('name', response.name);
        formData.append('sobrenamo', response.sobrenamo);
        formData.append('state', response.state);
        formData.append('country', response.country);
        formData.append('city', response.city);
        formData.append('cep', response.cep);
        formData.append('street', response.street);
        formData.append('number', response.number);
        formData.append('neighborhood', response.neighborhood);
        formData.append('job', response.job);
        formData.append('cnpj', response.cnpj);
        formData.append('type', response.type);
        formData.append('description', order.description);
        formData.append('resposta', response.resposta);
        formData.append('categoriaS', response.categoriaS);
        formData.append('categoriaF', response.categoriaF);
        formData.append('real', response.real);
        formData.append('voluntario', response.voluntario);
        formData.append('hour', response.hour);
        formData.append('availability', availability);
        formData.append('phone', response.phone);
        formData.append('active', response.active);
        formData.append('winner', response.winner);
        formData.append('currentLanguage', currentLanguage);

        saveResponseProviders(formData)
            .then(result => {
                setArrayImages([]);
                btnClose.current.click();
                if (props.onProposta) {
                    props.onProposta();
                }
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
            });
    }

    const onStarClick = (nextValue) => {
        setRating(nextValue);
    };

    const handleSubmit = async () => {
        try {
            await createRating({ itemId: vencedores[0].userId, userId: vencedores[0].clienteId, comments: feed.comments, rating });
            btnClose.current.click();
        } catch (error) {
            console.error(error);
        }
    };

    const onShowLimite = () => {
        setShowLimite(true);
    };

    const handlePremiumPlanClick = () => {
        setShowLimite(false);
        btnClose.current.click();
        history.push('/planoptions');
    };

    const handleClose = () => {
        setShowLimite(false);
    };


    const avgSubmit = async () => {
        try {
            const rating = await getAverageRating(vencedores[0].userId);
            setRating(rating.averageRating);
        } catch (error) {
            console.error(error);
        }
    };

    const localAddress = `${response.street}, ${response.number} ${response.city}`;
    const destinationAddress = `${order.street}, ${order.number} ${order.city}`;

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Pedido_${order.id}`,
    });

    return (
        <div>
            <div className="modal fade" id="modalViewOrder" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered custom-modal" role="document">
                    <div className="modal-content" ref={printRef}>
                        {order.rating && order.rating === true
                            ? <React.Fragment>
                                <div className="modal-header">
                                    <p className="modal-title">{t('Avaliação')}</p>
                                    <button ref={btnClose} type="button" className="print-hide btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                            <label htmlFor="comments">{t('Avaliar')} <b> 
                                                {
                                                    vencedores && vencedores !== null && vencedores.length > 0  
                                                    ? `${vencedores[0].name} ${vencedores[0].sobrenamo}`
                                                    : <React.Fragment></React.Fragment>
                                                }
                                            </b>
                                            </label>
                                            </div>
                                            <div className="col-md-12 mb-3 text-center">
                                                <div className="star-rating-container">
                                                    <StarRatingComponent
                                                        name="rating"
                                                        starCount={5}
                                                        value={rating}
                                                        onStarClick={onStarClick}
                                                        emptyStarColor="#1455aa"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="comments">{t('Comentário')}</label>
                                                <textarea 
                                                    id="comments" 
                                                    className="form-control mb-3" 
                                                    placeholder={t('Deixe seu comentário aqui...')}
                                                    value={feed.comments} 
                                                    style={{ 
                                                        width: '100%', 
                                                        height: 'auto', 
                                                        minHeight: '80px',  
                                                        overflow: 'hidden', 
                                                        whiteSpace: 'pre-wrap',
                                                        wordWrap: 'break-word'
                                                    }}
                                                    onChange={onInputFeedChange}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {error
                                        ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    <button type="button" className="print-hide btn btn-sm btn-success" onClick={handleSubmit}>{t('Enviar Avaliação')}</button>
                                    
                                </div>
                            </React.Fragment>
                            : <React.Fragment>
                                <div className="modal-header">
                                    <p className="modal-title">{t('Solicitação')}</p>
                                    <div className="ms-auto d-flex align-items-center">
                                        <button type="button" onClick={handlePrint} className="print-hide btn btn-xs btn-primary me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                                <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z" clipRule="evenodd" />
                                            </svg>

                                        </button>
                                        <button ref={btnClose} type="button" className="print-hide btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                                {chkResponse() === false
                                    ? <React.Fragment>
                                        {parseFloat(id) === parseFloat(order.userId)
                                            ? (
                                                <form>
                                                    <div className="modal-body">
                                                        <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-12 d-flex">
                                                                        <div className="col-md-8">                                            
                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-3">
                                                                                    <b>{t('Categoria')}: </b>
                                                                                        {
                                                                                            order.type === '3'
                                                                                                ? ( t(order.categoriaF) )
                                                                                                : ( t(order.categoriaS) )
                                                                                        }
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-3">
                                                                                    <b>{t('Estado-')} </b> <span className={getStatusClass(order.status)}>{agendado ? t('AGENDADO') : t(order.status)}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-3" >
                                                                                    <b>{t('Solicitação Nº')}:</b> #{order.id}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12 mb-3">
                                                                                    <b>{t('Data de abertura')}:</b> {formatarDataHora()}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                {order.disponibility !== null
                                                                                    ? <div className="col-12 mb-3">
                                                                                        <b>{t('Data requisitada')}:</b> {formatDate(order.disponibility)}
                                                                                    </div>
                                                                                    : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="print-hide col-md-4 d-flex flex-column align-items-center justify-content-center">
                                                                            {avatar && <Avatar imageUrl={avatar} alt="Avatar" size="big" frameStyle="double-shadow-black" />}
                                                                            <div>
                                                                                <b>{vencedores && vencedores !== null && vencedores.length > 0 
                                                                                        ? vencedores[0].name
                                                                                        : order.name
                                                                                    }
                                                                                </b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3" style={{
                                                                            width: '100%',
                                                                            height: 'auto',
                                                                            minHeight: '20px',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'pre-wrap',
                                                                            wordWrap: 'break-word'
                                                                        }}>
                                                                        <b>{t('Descrição')}:</b> {order.description}
                                                                    </div>
                                                                </div>
                                                                {order.images !== '[]'
                                                                    ? <React.Fragment>
                                                                        <div className="row">
                                                                            <div className="col-md-12 mb-3">
                                                                                <b>{t('Imagens')}: </b>
                                                                                <button id={"viewPhotos" + order.id} type="button" className="print-hide btn btn-primary animate-up-2 btn-xs" onClick={onImageChange}>
                                                                                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 mb-3">
                                                                                {renderImagePreviews()}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    : <React.Fragment></React.Fragment>
                                                                }
                                                            </div>
                                                            {vencedores && vencedores !== null && vencedores.length > 0
                                                                ? <div className="form-group">
                                                                    <div className="row">
                                                                        <div className="col-md-12 mb-3 print-hide">
                                                                            <b>{t('Aprovado')}: </b>
                                                                            {vencedores.map((item) => (
                                                                                <ButtonRow key={item.id} data={item} onClick={onWinnerClick} />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3">
                                                                        {renderWinnerPreviews()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        {order.active === true && Object.entries(vencedores).length === 0 && order.canceled === false
                                                            ? <button type="button" className="print-hide btn btn-sm btn-danger" onClick={onCancelClick}>{t('Cancelar')}</button>
                                                            : agendado
                                                                ? <button type="button" className="print-hide btn btn-sm btn-danger" onClick={onCancelClick}>{t('Cancelar')}</button>
                                                                : order.active === false || order.canceled === true
                                                                    ? <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onExcludeClick}>{t('Excluir')}</button>
                                                                    : <React.Fragment></React.Fragment>
                                                        }
                                                        {error
                                                            ? <div className="alert alert-danger mt-1 col-7 py-1">{error}</div>
                                                            : <React.Fragment></React.Fragment>
                                                        }
                                                    </div>
                                                </form>
                                             ) : (
                                                  order.status === 'ABERTO'
                                                    ? <form>
                                                        <div className="modal-body">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-12 d-flex">
                                                                        <div className="col-md-8">
                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-3">
                                                                                    <b>{t('Nome')}:</b> {order.name}<b> </b>{order.sobrenamo}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12 mb-3">
                                                                                    <b>{t('Data de abertura')}:</b> {formatarDataHora()}
                                                                                </div>
                                                                            </div>
                                                                            { order.disponibility !== null                                                                                 
                                                                                ? <div className="row">
                                                                                    <div className="col-12 mb-3">
                                                                                        <b>{t('Data requisitada')}:</b> {formatDate(order.disponibility)}
                                                                                    </div>
                                                                                    </div> 
                                                                                : <React.Fragment></React.Fragment>
                                                                            }                             
                                                                            {
                                                                                order.job && order.job !== null && order.job !== 'null'
                                                                                    ? (
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 mb-3">
                                                                                                <b>{t('Profissão')}:</b> {order.job}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            {
                                                                                order.cnpj && order.cnpj !== null && order.cnpj !== 'null'
                                                                                    ? (
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 mb-3">
                                                                                                <b>{t('CNPJ/CPF')}:</b> {order.cnpj}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className="col-md-4 d-flex flex-column align-items-center justify-content-center ms-4 me-4">
                                                                            {avatar && <Avatar imageUrl={avatar} alt="Avatar" size="big" frameStyle="double-shadow-black" />}
                                                                            <div><b>{order.name}</b></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3" style={{
                                                                        width: '100%',
                                                                        height: 'auto',
                                                                        minHeight: '20px',
                                                                        overflow: 'hidden',
                                                                        whiteSpace: 'pre-wrap',
                                                                        wordWrap: 'break-word'
                                                                        }}>
                                                                        <b>{t('Descrição')}:</b> {order.description}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    !showProposta
                                                                        ? (
                                                                            <React.Fragment>
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-3">
                                                                                        <b>{t('CEP')}: </b> {order.cep}
                                                                                    </div>
                                                                                    <div className="col-md-5 mb-3">
                                                                                        <b>{t('Cidade')}: </b> {order.city}
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-3">
                                                                                        <b>{t('Estado')}: </b> {order.state}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-3 mb-3">
                                                                                        <b>{t('Bairro')}: </b> {order.neighborhood}
                                                                                    </div>
                                                                                    <div className="col-md-5 mb-3">
                                                                                        <b>{t('Rua')}: </b> {order.street}
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-3">
                                                                                        <b>{t('Número')}: </b> {order.number}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-12 mb-3">
                                                                                        { order.images !== '[]'
                                                                                            ? <button id={"viewPhotos" + order.id} type="button" className="print-hide btn btn-success animate-up-2 btn-xs" onClick={onImageChange}>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                                                                                        <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clipRule="evenodd" />
                                                                                                    </svg>
                                                                                                </button>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                        <b> </b>
                                                                                        <button id={"viewMap" + order.id} type="button" className="print-hide btn btn-secondary animate-up-2 btn-xs" onClick={onMapChange}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                                                                                <path fillRule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-md-12 mb-3">
                                                                                        {renderImagePreviews()}
                                                                                        {renderMapPreviews()}
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                        : ( 
                                                                            <React.Fragment>
                                                                                <div className="row">
                                                                                    <div className="col-md-12 mb-3">
                                                                                        <label htmlFor="resposta">{t('Resposta')}</label>
                                                                                        <textarea
                                                                                            id="resposta"
                                                                                            className="form-control mb-3"
                                                                                            placeholder={t('Descreva seu orçamento, serviço ou o que você irá fornecer ao seu cliente ...')}
                                                                                            value={response.resposta}
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: 'auto',
                                                                                                minHeight: '80px',
                                                                                                overflow: 'hidden',
                                                                                                whiteSpace: 'pre-wrap',
                                                                                                wordWrap: 'break-word'
                                                                                            }}
                                                                                            onChange={onInputChange}>
                                                                                        </textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row image-input">
                                                                                    <div className="col-md-12 mb-3">
                                                                                        <label htmlFor="images">{t('Imagens de produtos, etc... Insira dentro do campo abaixo!')}</label>
                                                                                        <input type="file" id="PropImages" className="form-control" multiple onChange={onImageReChange} accept="image/*" />
                                                                                        {renderImageRePreviews()}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-1 mb-3">
                                                                                        <label htmlFor="description"> </label>
                                                                                    </div>
                                                                                    <div className="col-md-5 mb-3">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="description">{t('Disponibilidade')}</label>
                                                                                            <FornecedorCalendar onAvailabilityChange={handleAvailabilityChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-5 mb-3">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="symbol">{t('Horário')}</label>
                                                                                            <SelectHour value={response.hour} onChange={onHourChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-3">
                                                                                        <label htmlFor="description"> </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-1 mb-3">
                                                                                        <label htmlFor="description"> </label>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-3">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="real">{t('Valor R$')}:</label>
                                                                                            <input className="form-control" id="real" type="text" placeholder={response.voluntario ? t('Muito obrigado') : ''} disabled={response.voluntario} value={response.real || ''} onChange={onInputChange} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-3">
                                                                                        <label htmlFor="description"> </label>
                                                                                    </div>
                                                                                    <div className="col-md-4 mb-3">
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" type="checkbox" id="voluntario" checked={response.voluntario} onChange={onCheckboxChange} />
                                                                                            <label className="form-check-label" htmlFor="voluntario">{t('Voluntário')}.</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1 mb-3">
                                                                                        <label htmlFor="description"> </label>
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                          )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    {
                                                                        error
                                                                            ? <div className="alert alert-danger mt-1 col-7 py-1">{error}</div>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                    {   !showProposta
                                                                            ? <button type="button" className="print-hide btn btn-sm btn-danger" onClick={onEndClick}>{t('Rejeitar')}</button>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                    {   !props.isDisabled 
                                                                            ? <button type="button" className="print-hide btn btn-sm btn-primary" onClick={onPropostaClick}>{!showProposta ? t('Fazer proposta!') : t('Voltar')} </button>
                                                                            : <button type="button" className="print-hide btn btn-sm btn-danger" onClick={onShowLimite}>{t('Limite Atingido!')}</button>
                                                                    }
                                                                    
                                                                    {
                                                                        showProposta
                                                                            ? <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onSubmit}>{t('Enviar proposta!')}</button>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                    
                                                                </div>
                                                            </form>
                                                        : <React.Fragment>
                                                        <div className="modal-body">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-3">
                                                                        <b>{t('Solicitação Nº')}:</b> #{order.id}
                                                                    </div>
                                                                    <div className="col-md-6 mb-3">
                                                                        <b>{t('Estado-')} </b> <span className="badge bg-info py-1">{t('FINALIZADO')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3">
                                                                        <b>{t('Nome')}:</b> {order.name}<b> </b>{order.sobrenamo}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 mb-3">
                                                                        <b>{t('Data de abertura')}:</b> {formatarDataHora()}
                                                                    </div>
                                                                </div>
                                                                {order.disponibility !== null
                                                                    ? <div className="row">
                                                                        <div className="col-6 mb-3">
                                                                            <b>{t('Data requisitada')}:</b> {formatDate(order.disponibility)}
                                                                        </div>
                                                                    </div>
                                                                    : <React.Fragment></React.Fragment>
                                                                }
                                                                <div className="row">
                                                                    <div className="col-md-12 mb-3">
                                                                        <b>{t('Descrição')}: </b> {order.description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="print-hide btn btn-sm btn-secondary" onClick={onEndClick}>{t('Excluír')}</button>
                                                        </div>
                                                    </React.Fragment>
                                                    )
                                                }
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <form>
                                            <div className="modal-body">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            {checkProviderStatus()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
            {showLimite && (
                <div className="modal-limite-atingido-backdrop">
                    <div className="modal-limite-atingido-dialog">
                        <div className="modal-limite-atingido-content">
                            <div className="modal-limite-atingido-header">
                                <button type="button" className="modal-limite-atingido-close" onClick={handleClose}>×</button>
                            </div>
                            <div className="modal-limite-atingido-body">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="red" className="modal-limite-atingido-icon" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995c-.04-.53.389-.995.92-.995h.04c.531 0 .961.47.92.995l-.348 3.993a.548.548 0 0 1-1.09 0L7.1 4.995z" />
                                </svg>
                                <h4 className="modal-limite-atingido-title">Limite Atingido</h4>
                                <p>{t('Você atingiu o limite de')} <span className="modal-limite-atingido-highlight">{t('2 propostas simultâneas')}</span>.</p>
                                <p>{t('Para enviar mais propostas, aguarde uma solicitação ser encerrada ou adquira o plano premium.')}</p>
                                <button className="modal-limite-atingido-btn-primary" onClick={handlePremiumPlanClick}>
                                    {t('Adquirir Plano Premium')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ViewProvidersModal;
