import React from 'react';
import { useTranslation } from 'react-i18next';

const ThankYouPage = () => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.location.href = "myapp://";
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>{t('Parabéns por escolher o Plano Premium da Yepwedo!')}</h1>
      <p>{t('Estamos muito felizes em ter você conosco. Com o plano Premium, você terá acesso a todos os recursos exclusivos que preparamos especialmente para impulsionar o seu sucesso.')}</p>
      <p>{t('Vamos começar essa jornada juntos! Conte com a gente para o que precisar.')}</p>
      <button 
        onClick={handleRedirect} 
        style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
      >
        {t('Voltar')}
      </button>
    </div>
  );
};

export default ThankYouPage;