import React, { useState, useEffect, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';
import '../Dashboard.css'
import OrdersRow from './OrdersRow';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getSuppliers, getMyOrders, getProviders, getMyProviders } from '../../../services/OrdersService';
import { whoIAm, getSettings } from '../../../services/SettingsService';
import ViewOrdersModal from './ViewOrdersModal';
import { useTranslation } from 'react-i18next';


function Orders(props) {
    const { t } = useTranslation();

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
          setIsLargeScreen(window.innerWidth >= 992);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const defaultLocation = useLocation();
    const id = localStorage.getItem('id');

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    const { symbol } = useParams();

    const [search, setSearch] = useState('');

    const [orders, setOrders] = useState([]);

    const [settings, setSettings] = useState({});

    const [notification, setNotification] = useState([]);

    const [count, setCount] = useState(0);

    const [fornecedor, setFornecedor] = useState(false);

    const [viewOrder, setViewOrder] = useState({});

    const [page, setPage] = useState(getPage());

    const [isLoading, setIsLoading] = useState(false);

    const [refresh, setRefresh] = useState(0);

    const [myType, setMyType] = useState('');

    const [selectedSupplier, setSelectedSupplier] = useState(null);


    useEffect(() => {
        if (props.refresh) {
            setRefresh(props.refresh);
        }
    }, [props.refresh])

    useEffect(() => {
            getSettings()
                .then(result => setSettings(result))
                .catch(err => console.error(err.response ? err.response.data : err.message));
            
            setIsLoading(true);
            whoIAm()
                .then(result => {
                    setMyType(result);
                    if (result === '1') {
                        getMyOrders()
                            .then(result => {
                                //console.log(result);
                                setOrders(result.rows);
                                setCount(result.count);
                                setViewOrder(result.rows[0]);
                                setIsLoading(false);
                            })
                            .catch(err => {
                                console.error(err.response ? err.response.data : err.message);
                                setIsLoading(true);
                            })
                    } else if (result === '2') {
                        getProviders(search, page || 1)
                            .then(result => {
                                //console.log(result);
                                setOrders(result.rows);
                                setCount(result.count);
                                setViewOrder(result.rows[0]);
                                setIsLoading(false);
                                const negado = result.rows.map(object => object.services.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO'));
                                console.log('OLHA', negado);
                                
                            })
                            .catch(err => {
                                console.error(err.response ? err.response.data : err.message);
                                setIsLoading(true);
                            })
                    } else {
                        getSuppliers(search, page || 1)
                            .then(result => {
                                //console.log(result);
                                setOrders(result.rows);
                                setCount(result.count);
                                setViewOrder(result.rows[0]);
                                setIsLoading(false);
                            })
                            .catch(err => {
                                console.error(err.response ? err.response.data : err.message);
                                setIsLoading(true);
                            })
                    }
                })
                .catch(err => {
                    console.error(err.response ? err.response.data : err.message);
                    setIsLoading(true);
                })
    }, [page, search, refresh])

    useEffect(() => {
        if (myType && props.onMyType) {
            props.onMyType(myType);
        }
    }, [myType])


    /*const countAnalysisOrders = (orders) => {
        return orders.reduce((count, order) => {
          const analysisOrders = order.services
            ? order.services.filter(service => service.active && !service.winner)
            : order.suppliers.filter(supplier => supplier.active && !supplier.winner);
          
          return count + analysisOrders.length;
        }, 0);
    };*/

    const countAnalysisOrders = (orders, settings) => {
        return orders.reduce((count, order) => {
          const analysisOrders = order.services
            ? order.services.filter(service => {
                //console.log(service);
                return service.active && !service.winner && parseFloat(service.userId) === parseFloat(settings.id);
              })
            : order.suppliers.filter(supplier => supplier.active && !supplier.winner && parseFloat(supplier.userId) === parseFloat(settings.id));
          
          //console.log( analysisOrders.length);
          return count + analysisOrders.length;
        }, 0);
    };

    const analysisCount = countAnalysisOrders(orders, settings);
    const isDisabled = useMemo(() => {
        return settings.type !== '1' && settings.priority === 0 && analysisCount > 1;
    }, [settings.type, settings.priority, analysisCount]);
    
    //const analysisCount = countAnalysisOrders(orders);
    //const isDisabled = settings.type !== '1' && settings.priority === 0 && analysisCount > 2;
    //alert(analysisCount);
    //alert(isDisabled);

    function onViewOrder(Data) {
        console.log(Data);
        if (Data.length !== 0) {
            props.onViewOrder(Data);
        }
    }

    function onMyLocation(Data) {
        props.onMyLocation(Data);
    }

    function onMyDestination(Data) {
        props.onMyDestination(Data);
    }

    function onActive(Data) {
        props.onActive(Data);
    }

    function onPropostaSubmit(order) {
        setRefresh(Date.now());
        props.onProposta();
        //setNotification({ type: 'success', text: "Proposta realizada com sucesso!" });
    }

    function onFinishOrder(order) {
        setRefresh(Date.now());
        props.onFinishOrder();
        //setNotification({ type: 'success', text: "Excluído com sucesso!" });
    }

    function onExclude(order) {
        setRefresh(Date.now());
        props.onExclude();
    }

    function onCancel(order) {
        setRefresh(Date.now());
        props.onCancel();
    }

    function onViewClick(event) {
        const id = event.target.parentElement.id.replace('view', '');
        const order = orders.find(o => o.id == id);
        setViewOrder({ ...order });
    }


    const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            console.log(`Connected Dash Orders`);
        },
        onMessage: (message) => {
            const data = JSON.parse(message.data);
            if (data.type === 'refreshOrders') {
                setRefresh(Date.now());
            }
        },
        queryParams: { 'token': localStorage.getItem("token") },
        onError: (event) => {
            console.error(JSON.stringify(event));
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });


    return (
        <>
        { isLargeScreen 
            ? (
                <React.Fragment>
                    <div className="col-sm-12 col-md-12 mb-4">
                        <div className="card border-0 shadow">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="fs-5 fw-bold mb-0">{myType === '1' ? t('Solicitações Em Andamento') : t('Atendimentos')}</h2>
                                    </div>
                                    <div className="col offset-md-3">

                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive divScroll">
                                <table className="table align-items-center table-flush table-sm table-hover tableFixHead" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                    <thead className="thead-light" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                        <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Nº</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DATA')}</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('CATEGORIA')}</th>
                                            {   myType === '1'
                                                    ? <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('RESPOSTAS')}</th>
                                                    : <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('ESTADO')}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {orders && orders.length ? (
                                            orders.map(item => {
                                                return (
                                                    item.active === true && item.canceled === false && item.type === '2' && item.services && item.services.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                        ? <React.Fragment key={item.id}></React.Fragment>
                                                        : item.active === true && item.canceled === false && item.type === '3' && item.suppliers && item.suppliers.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                            ? <React.Fragment key={item.id}></React.Fragment>
                                                            : item.active === true && item.canceled === false
                                                                ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onClick={onViewClick} onActive={onActive}/>
                                                                : <React.Fragment key={item.id}></React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                        <div className="card border-0 shadow">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="fs-5 fw-bold mb-0">{myType === '1'? t('Solicitações Aprovadas') : t('Atendimentos Processados')}</h2>
                                    </div>
                                    <div className="col offset-md-3">

                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive divScroll">
                                <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="border-bottom col-2" scope="col">Nº</th>
                                            <th className="border-bottom col-2" scope="col">{t('DATA')}</th>
                                            <th className="border-bottom col-2" scope="col">{t('CATEGORIA')}</th>
                                            <th className="border-bottom col-2" scope="col">{t('ESTADO')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {orders && orders.length ? (
                                            orders.map(item => {
                                                return (
                                                    item.active === true && item.canceled === false && item.type === '2' && item.services && item.services.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                        ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                        : item.active === true && item.canceled === false && item.type === '3' && item.suppliers && item.suppliers.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                            ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                            : item.active === false || item.canceled === true
                                                                ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                                : <React.Fragment key={item.id}></React.Fragment>
                                                   
                                                    );
                                            })
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (

                <React.Fragment>
                    <div className="col-sm-12 col-md-12 mb-4">
                        <div className="card border-0 shadow">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="fs-5 fw-bold mb-0">{myType === '1' ? t('Solicitações Aprovadas') : t('Atendimentos')}</h2>
                                    </div>
                                    <div className="col offset-md-3">

                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive divScroll" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                <table className="table align-items-center table-flush table-sm table-hover tableFixHead" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}> 
                                    <thead className="thead-light" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                        <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}> 
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Nº</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DATA')}</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('CATEGORIA')}</th>
                                            {   myType === '1'
                                                    ? <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('RESPOSTAS')}</th>
                                                    : <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('ESTADO')}</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {orders && orders.length ? (
                                            orders.map(item => {
                                                return (
                                                    item.active === true && item.canceled === false && item.type === '2' && item.services && item.services.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                        ? <React.Fragment key={item.id}></React.Fragment>
                                                        : item.active === true && item.canceled === false && item.type === '3' && item.suppliers && item.suppliers.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                            ? <React.Fragment key={item.id}></React.Fragment>
                                                            : item.active === true && item.canceled === false
                                                                ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onClick={onViewClick} onActive={onActive}/>
                                                                : <React.Fragment key={item.id}></React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                        <div className="card border-0 shadow">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="fs-5 fw-bold mb-0">{myType === '1'? t('Solicitações Aprovadas') : t('Atendimentos Processados')}</h2>
                                    </div>
                                    <div className="col offset-md-3">

                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive divScroll" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                <table className="table align-items-center table-flush table-sm table-hover tableFixHead" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}> 
                                    <thead className="thead-light" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                        <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}> 
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>Nº</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DATA')}</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('CATEGORIA')}</th>
                                            <th className="border-bottom col-2" scope="col" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('ESTADO')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {orders && orders.length ? (
                                            orders.map(item => {
                                                return (
                                                    item.active === true && item.canceled === false && item.type === '2' && item.services && item.services.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                    ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                    : item.active === true && item.canceled === false && item.type === '3' && item.suppliers && item.suppliers.some(item => parseFloat(item.userId) === parseFloat(id) && item.status === 'REJEITADO')
                                                        ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                        : item.active === false || item.canceled === true
                                                            ? <OrdersRow key={item.id} data={item} myType={myType} onViewOrder={onViewOrder} onMyLocation={onMyLocation} onMyDestination={onMyDestination} onClick={onViewClick} onActive={onActive}/>
                                                            : <React.Fragment key={item.id}></React.Fragment>
                                                   
                                                );
                                            })
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        <ViewOrdersModal isDisabled={isDisabled} data={viewOrder} myType={myType} onProposta={onPropostaSubmit} onFinishOrder={onFinishOrder} onExclude={onExclude} onCancel={onCancel} />
        </>
    );
}

export default Orders;
